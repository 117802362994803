import React from "react";
import CalendarIcon from '../../../static/assets/calendarIcon.svg';
import AuthorIcon from '../../../static/assets/user/user.svg';
import ClockIcon from '../../../static/assets/clockIcon.svg';
import { GatsbyImage } from "gatsby-plugin-image";
const ArticleHomeComponent = (props) =>{
    const {
      articleHomeTitle,
      articleHomeData,
      articleHomeAuthor,
      articleHomeTimeForReading,
      articleImage,
    } = props;
    return (
      <div className="article-home-section">
        <div className="article-home-section-wave"></div>
        <div className="full-container">
          <div className=" article-home-section-container">
            <div className="article-home-section-info mobile">
              <p className="article-home-section-info-text">
                <span className="article-home-section-info-icon">
                  <CalendarIcon />
                </span>
                {articleHomeData}
              </p>
              <p className="article-home-section-info-text">
                <span className="article-home-section-info-icon">
                  <AuthorIcon />
                </span>
                {articleHomeAuthor}
              </p>
              <p className="article-home-section-info-text-isEnd">
                <span className="article-home-section-info-icon">
                  <ClockIcon />
                </span>
                {articleHomeTimeForReading}
              </p>
            </div>
            <h1 className="article-home-section-title ">{articleHomeTitle}</h1>
            <div className="article-home-section-info ">
              <p className="article-home-section-info-text">
                <span className="article-home-section-info-icon">
                  <CalendarIcon />
                </span>
                {articleHomeData}
              </p>
              <p className="article-home-section-info-text">
                <span className="article-home-section-info-icon">
                  <AuthorIcon />
                </span>
                {articleHomeAuthor}
              </p>
              <p className="article-home-section-info-text-isEnd">
                <span className="article-home-section-info-icon">
                  <ClockIcon />
                </span>
                {articleHomeTimeForReading}
              </p>
            </div>
            <GatsbyImage
              image={articleImage}
              className="article-home-section-image"
            />
          </div>
        </div>
      </div>
    );
}
export default ArticleHomeComponent