import React from "react";
import Header from '../components/headerComponent';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ArticleHomeComponent from '../components/Blog/articleHomeComponent';
import ArticleComponent from '../components/Blog/articleComponent';
import NewFooterComponent from '../components/newFooterComponent';
const BlogArticleStopShareIdea = (props) =>{
        const {
          data: {
            wpPost: {
              seo,
              blogArticleSection: {
                blogArticleAuthor,
                blogArticleData,
                blogArticleReadingTime,
                blogArticleText,
                blogArticleTitle,
                blogArticleImage: {
                  localFile: {
                    childImageSharp: { gatsbyImageData: blogArticleImage },
                  },
                },
                blogRecommendedArticleEmployeesImage: {
                  localFile: {
                    childImageSharp: {
                      gatsbyImageData: blogRecommendedArticleEmployeesImage,
                    },
                  },
                },
                blogRecommendedArticleStrategyImage: {
                  localFile: {
                    childImageSharp: {
                      gatsbyImageData: blogRecommendedArticleStrategyImage,
                    },
                  },
                },
                blogRecommendedArticleStudentsImage: {
                  localFile: {
                    childImageSharp: {
                      gatsbyImageData: blogRecommendedArticleStudentsImage,
                    },
                  },
                },
                blogArticleSectionTwoText,
                blogArticleSectionThreeText,
                blogArticleSecondImage: {
                  localFile: {
                    childImageSharp: {
                      gatsbyImageData: blogArticleSecondImage,
                    },
                  },
                },
                blogArticleThirdImage: {
                  localFile: {
                    childImageSharp: { gatsbyImageData: blogArticleThirdImage },
                  },
                },
              },
            },
          },
          pageContext: { title },
        } = props;
    return (
      <Layout seo={seo} title={title}>
        <Header />
        <ArticleHomeComponent
          articleHomeTitle={blogArticleTitle}
          articleHomeData={blogArticleData}
          articleHomeAuthor={blogArticleAuthor}
          articleHomeTimeForReading={blogArticleReadingTime}
          articleImage={blogArticleImage}
          blogArticleStrategyImage={blogRecommendedArticleStrategyImage}
          blogArticleStudentsImage={blogRecommendedArticleStudentsImage}
          blogArticleStopSharingIdeasImage={
            blogRecommendedArticleEmployeesImage
          }
        />
        <ArticleComponent
          articleText={blogArticleText}
          blogArticleStrategyImage={blogRecommendedArticleStrategyImage}
          blogArticleStudentsImage={blogRecommendedArticleStudentsImage}
          blogArticleStopSharingIdeasImage={
            blogRecommendedArticleEmployeesImage
          }
          articleDescription={blogArticleSectionTwoText}
          articleSubDescription={blogArticleSectionThreeText}
          articleSecondImage={blogArticleSecondImage}
          articleThirdImage={blogArticleThirdImage}
          tagsName="Change Management"
          categoriesName="Idea Management"
          blogArticleTitle="How leaders can encourage their employees to share their ideas"
          blogArticleData="16 Jun 2022"
          blogArticleTime="3 min read"
          blogArticleTwoData="08 May 2022"
          blogArticleTwoTime="3 min read"
          blogArticleTwoTitle="How to encourage inclusivity and innovation in your college class"
          blogArticleThreeData="11 Feb 2022"
          blogArticleThreeTime="5 min read"
          blogArticleThreeTitle="Why Employees Stop Sharing Ideas"
          firstArticleRecommendedLink="/blog/how-leaders-can-encourage-their-employees-to-share-their-ideas"
          SecondArticleRecommendedLink="/blog/how-to-encourage-inclusivity-and-innovation-in-your-college-class"
          thirdArticleRecommendedLink="/blog/why-employees-stop-sharing-ideas"
        />
        <NewFooterComponent isProduct={true} />
      </Layout>
    );
}
export const query = graphql`
  query {
    wpPost(slug: { eq: "why-employees-stop-sharing-ideas" }) {
      seo {
        metaDesc
        title
      }
      blogArticleSection {
        blogArticleAuthor
        blogArticleData
        blogArticleReadingTime
        blogArticleText
        blogArticleTitle
        blogArticleImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleEmployeesImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleStrategyImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogRecommendedArticleStudentsImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogArticleSectionTwoText
        blogArticleSectionThreeText
        blogArticleSecondFore
        blogArticleSecondImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogArticleThirdImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default BlogArticleStopShareIdea