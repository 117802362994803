import { Link } from 'gatsby';
import React from 'react';
import AgreedLogo from '../../static/assets/newFooterLogo.svg';
import TwitterLogo from '../../static/assets/twitterColor.svg';
import FacebookLogo from '../../static/assets/facebookColor.svg';
import LinkedinLogo from '../../static/assets/linkedinColor.svg';
import InstagramLogo from '../../static/assets/instagram.svg';
const NewFooterComponent = props => {
  const { isProduct } = props;
  return (
    <div
      className={`new-footer-section ${
        isProduct ? 'new-footer-section-product' : ''
      }`}
    >
      <div className="full-container">
        <div className="row">
          <div className="col-6">
            <AgreedLogo className="new-footer-section-logo" />
          </div>
          <div className="col-6">
            <div className="new-footer-section-icon">
              <a
                aria-label="instagram"
                href="https://www.instagram.com/agreedsoftware/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramLogo className="new-footer-section-icon-twitter" />
              </a>
              <a
                aria-label="twitter"
                href="https://twitter.com/SoftwareAgreed"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterLogo className="new-footer-section-icon-twitter" />
              </a>
              <a
                aria-label="fb"
                href="https://www.facebook.com/agreedsoftware"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookLogo className="new-footer-section-icon-twitter" />
              </a>
              <a
                aria-label="linkedin"
                href="https://www.linkedin.com/company/agreed-software/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinLogo className="new-footer-section-icon-linkedin" />
              </a>
            </div>
          </div>
        </div>
        <div className="new-footer-section-wrappper">
          <div className="new-footer-section-copyright-section">
            <p className="new-footer-section-copyright">
              &copy; 2023 Agreed Software
            </p>
          </div>
          <div className="new-footer-section-wrappper-text">
            <div className="new-footer-section-wrappper-link-section">
              <div className="new-footer-section-link">
                <Link
                  className="new-footer-section-subtitle"
                  to="/change-management-process"
                >
                  Product
                </Link>
              </div>
              <div className="new-footer-section-link">
                <Link
                  to="/innovation-management-process"
                  className="new-footer-section-subtitle"
                >
                  Solutions
                </Link>
              </div>
              <div className="new-footer-section-link">
                <Link to="/contact" className="new-footer-section-subtitle">
                  Pricing
                </Link>
              </div>
              <div className="new-footer-section-link">
                <a
                  aria-label="agreed service"
                  href="https://app.agreed.io/legal/terms-of-use/service"
                  target="_blank"
                  rel="noreferrer"
                  className="new-footer-section-subtitle"
                >
                  Terms of Use
                </a>
              </div>
              <div className="new-footer-section-contact">
                <div>
                  <div className="new-footer-section-link">
                    <Link
                      to="/contact"
                      className="new-footer-section-subtitle contact"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewFooterComponent;
