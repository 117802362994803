import React from 'react';
import TimeIcon from '../../../static/assets/timeIcon.svg';
import { GatsbyImage } from 'gatsby-plugin-image';
import RecommendedIcon from '../../../static/assets/recommended.svg';
import { Link } from 'gatsby';
import TagsIcon from '../../../static/assets/tagsIcon.svg';
const ArticleComponent = props => {
  const {
    articleText,
    blogArticleData,
    blogArticleTime,
    blogArticleStrategyImage,
    blogArticleStudentsImage,
    blogArticleStopSharingIdeasImage,
    articleSecondImage,
    articleDescription,
    articleThirdImage,
    articleSubDescription,
    articleEndDescription,
    articleForthImage,
    tagsName,
    categoriesName,
    blogArticleTitle,
    blogArticleTwoData,
    blogArticleTwoTime,
    blogArticleTwoTitle,
    blogArticleThreeData,
    blogArticleThreeTime,
    blogArticleThreeTitle,
    firstArticleRecommendedLink,
    SecondArticleRecommendedLink,
    thirdArticleRecommendedLink,
  } = props;
  return (
    <div className="article-blog-section">
      <div className="full-container">
        <div className="row">
          <div className="col-md-9">
            <div className="article-blog-section-text-container">
              <p
                className="article-blog-section-text"
                dangerouslySetInnerHTML={{
                  __html: articleText,
                }}
              ></p>
              <GatsbyImage
                image={articleSecondImage}
                style={{
                  width: '100%',
                  maxWidth: '850px',
                  marginBottom: '10px',
                }}
              />
              <p
                className="article-blog-section-text"
                dangerouslySetInnerHTML={{
                  __html: articleDescription,
                }}
              ></p>
              <GatsbyImage
                image={articleThirdImage}
                style={{
                  width: '100%',
                  maxWidth: '850px',
                  marginBottom: '10px',
                }}
              />
              <p
                className="article-blog-section-text"
                dangerouslySetInnerHTML={{
                  __html: articleSubDescription,
                }}
              ></p>
              <GatsbyImage
                image={articleForthImage}
                style={{
                  width: '100%',
                  maxWidth: '850px',
                  marginBottom: '10px',
                }}
              />
              <p
                className="article-blog-section-text"
                dangerouslySetInnerHTML={{
                  __html: articleEndDescription,
                }}
              ></p>
              <div className="article-blog-section-tags">
                <TagsIcon />
                <p className="article-blog-section-tags-text-name">
                  {tagsName}
                </p>
                <p className="article-blog-section-tags-text">
                  {categoriesName}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="article-blog-section-wrapper">
              <div className="article-blog-section-newletter">
                <div className="article-blog-section-newletter-wrapper">
                  <p className="article-blog-section-newletter-text">
                    Sign up to get the latest Agreed updates and tips delivered
                    to your inbox.
                  </p>
                </div>
                <a
                  aria-label="newsletter"
                  className="article-blog-section-newletter-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://cdn.forms-content.sg-form.com/58c49499-4be2-11ed-ab1b-463471d968c7"
                >
                  Subscribe to our newsletter
                </a>
              </div>
              <p className="blog-article-section-popular-subtitle recommended">
                <span className="blog-article-section-popular-subtitle-icon">
                  <RecommendedIcon />
                </span>
                Recommended Articles
              </p>
              <Link
                to={firstArticleRecommendedLink}
                className="blog-article-section-post-recommended"
              >
                <GatsbyImage
                  image={blogArticleStrategyImage}
                  className="blog-article-section-image-recommended"
                />
                <div className="blog-article-section-post-card-recommended">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      {blogArticleData}
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      {blogArticleTime}
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    {blogArticleTitle}
                  </p>
                  <Link
                    className="blog-article-section-post-card-link"
                    to={firstArticleRecommendedLink}
                  >
                    Read More
                  </Link>
                </div>
              </Link>
              <Link
                to={SecondArticleRecommendedLink}
                className="blog-article-section-post-recommended"
              >
                <GatsbyImage
                  image={blogArticleStudentsImage}
                  className="blog-article-section-image-recommended"
                />
                <div className="blog-article-section-post-card-recommended">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      {blogArticleTwoData}
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      {blogArticleTwoTime}
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    {blogArticleTwoTitle}
                  </p>
                  <Link
                    className="blog-article-section-post-card-link"
                    to={SecondArticleRecommendedLink}
                  >
                    Read More
                  </Link>
                </div>
              </Link>
              <Link
                to={thirdArticleRecommendedLink}
                className="blog-article-section-post-recommended"
              >
                <GatsbyImage
                  image={blogArticleStopSharingIdeasImage}
                  className="blog-article-section-image-recommended"
                />
                <div className="blog-article-section-post-card-recommended">
                  <div className="blog-article-section-post-card-info">
                    <p className="blog-article-section-post-card-info-text">
                      {blogArticleThreeData}
                    </p>
                    <p className="blog-article-section-post-card-info-text">
                      <span className="blog-article-section-post-card-info-icon">
                        <TimeIcon />
                      </span>
                      {blogArticleThreeTime}
                    </p>
                  </div>
                  <p className="blog-article-section-post-card-title">
                    {blogArticleThreeTitle}
                  </p>
                  <Link
                    className="blog-article-section-post-card-link"
                    to={thirdArticleRecommendedLink}
                  >
                    Read More
                  </Link>
                </div>
              </Link>
              <div className="article-blog-section-newletter-isEnd">
                <div className="article-blog-section-newletter-wrapper">
                  <p className="article-blog-section-newletter-text">
                    Learn why Agreed is the innovation management platform of
                    the future.
                  </p>
                </div>
                <a
                  aria-label="get started"
                  className="article-blog-section-newletter-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://cdn.forms-content.sg-form.com/58c49499-4be2-11ed-ab1b-463471d968c7"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ArticleComponent;
